import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { LayoutComponent } from './layout/layout.component';
import { ThemeService } from './theme.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [
    LayoutComponent,
    RouterOutlet,
    ToastModule,
    ConfirmDialogModule
  ]
})
export class AppComponent implements OnInit {
  constructor(private primengConfig: PrimeNGConfig) {
    inject(ThemeService);
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }
}
