import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, distinctUntilChanged } from "rxjs";

export type PreferedAppTheme = 'dark' | 'light';
const LOCAL_STORAGE_KEY = 'theme-preference';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private _theme!: BehaviorSubject<PreferedAppTheme>;

  public get theme$() { return this._theme.asObservable().pipe(distinctUntilChanged()); }

  constructor(@Inject(DOCUMENT) private document: Document) {
    let theme = localStorage.getItem(LOCAL_STORAGE_KEY) as PreferedAppTheme;
    if (!theme) {
      theme = window.matchMedia?.("(prefers-color-scheme: dark)").matches ? 'dark' : 'light';
    }
    this._theme = new BehaviorSubject<PreferedAppTheme>(theme);
    this.setTheme('light', false)
  }

  setTheme(theme: PreferedAppTheme, remember: boolean = true) {
    const linkElement = this.document.getElementById('app-theme') as HTMLLinkElement;
    if (linkElement) {
      linkElement.href = `${theme}-theme.css`
    }
    if (remember) {
      localStorage.setItem(LOCAL_STORAGE_KEY, theme);
    }
    this._theme.next('light');
  }
}
