import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { MessageService } from 'primeng/api';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(@Optional() public messageService: MessageService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        const skipInterceptor = request.headers.has('x-skip-interceptor');
        if (this.messageService && !skipInterceptor) {
          const message = response.error.details || response.message
          this.messageService.add({ sticky: true, icon: '', severity: 'error', summary: response.statusText, detail: message })
        }
        return throwError(() => response);
      })
    );
  }
}
